import React from 'react'
import PropTypes from 'prop-types'

import Project from '@/components/Projects/ProjectItem'

class ProjectsFeed extends React.Component {
  render() {
    const { data } = this.props
    let { edges: projects } = data.allMarkdownRemark
    const {
      frontmatter: { projects: order },
    } = data.order

    return (
      <div className='feed feed--15 feed--rel'>
        {order.map((id, i) => {
          return projects.map(({ node: project }, x) => {
            if (project.frontmatter.id === id) {
              projects = projects.filter(
                ({ node: item }) => id !== item.frontmatter.id,
              )
              return <Project project={project} key={project.id} index={i} />
            } else {
              return null
            }
          })
        })}
        {projects.length > 0 &&
          projects.map(({ node: project }) => (
            <Project project={project} key={project.id} />
          ))}
      </div>
    )
  }
}

ProjectsFeed.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ProjectsFeed
