import React, { Component } from 'react'
import Image from '@/components/Image'
import { Link } from 'gatsby'

export default class Project extends Component {
  render() {
    const project = this.props.project

    return (
      <article className='project' data-aos='fade-up'>
        <Link className='project__permalink' to={project.fields.slug}>
          {project.frontmatter.video?.active ? (
            <video
              src={project.frontmatter.video.url}
              className='featured-video'
              autoPlay
              muted
              loop
            />
          ) : (
            project.frontmatter.thumbnail && (
              <Image
                className='project__image'
                author={project.frontmatter.thumbnail.author}
                image={project.frontmatter.thumbnail.image}
              />
            )
          )}
          <h2 className='project__name title-5 mt-24'>
            <span>{project.frontmatter.title}</span>
          </h2>
        </Link>
      </article>
    )
  }
}
