import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default class Image extends Component {
  constructor(props) {
    super(props)
    this.state = {
      author: this.props.author,
      image: this.props.image,
      loading: this.props.loading ? this.props.loading : 'lazy',
    }
  }

  render() {
    let imageClass = 'image'
    imageClass += this.props.className ? ` ${this.props.className}` : ''
    return (
      <figure
        className={imageClass}
        data-aos={this.props.aos}
        data-aos-delay={this.props.delay}>
        {this.state.image && (
          <GatsbyImage
            image={getImage(this.state.image)}
            loading={this.state.loading}
          />
        )}
        {this.state.author ? (
          <figcaption className='image__caption'>
            {this.state.author}
          </figcaption>
        ) : (
          ''
        )}
      </figure>
    )
  }
}
